// SkeletonData.js
const skeletonData = [
  { caption: 'Boilerplate Merchants', aspectRatio: 3 / 4 },
  { caption: 'Messages and Means', aspectRatio: 4 / 3 },
  { caption: 'Human Plague', aspectRatio: 4 / 3 },
  { caption: 'Chat GHB', aspectRatio: 4 / 3 },
  { caption: 'No Rubbish Dumpers', aspectRatio: 4 / 3 },
  { caption: 'Prohibited Papers', aspectRatio: 4 / 3 },
  { caption: 'Only Kids Draw On A Wall', aspectRatio: 3 / 4 },
  { caption: 'A Code For "Fuck You"', aspectRatio: 4 / 3 },
  { caption: 'You Just Get Bigger', aspectRatio: 3 / 4 },
  { caption: 'All Along', aspectRatio: 4 / 3 },
  { caption: 'Chipping Away', aspectRatio: 3 / 4 },
  { caption: 'LightLine', aspectRatio: 3 / 4 },
  { caption: 'Spread Love', aspectRatio: 3 / 4 },
  { caption: 'No Money In It', aspectRatio: 0.86 },
  { caption: 'Never Snitch' },
  { caption: 'No Name', aspectRatio: 4 / 3 },
  { caption: 'Markers', aspectRatio: 3 / 4 },
  { caption: 'Raw Use', aspectRatio: 4 / 3 },
  { caption: 'Box Ticking', aspectRatio: 4 / 3 },
  { caption: 'Squaring the Circle', aspectRatio: 3 / 4 },
  { caption: 'Unavailable to Test', aspectRatio: 4 / 3 },
  { caption: 'Potential Display', aspectRatio: 4 / 3 },
  { caption: 'CF', aspectRatio: 3 / 4 },
  { caption: 'Wax', aspectRatio: 4 / 3 },
  { caption: 'Only', aspectRatio: 4 / 3 },
  { caption: 'Ad Panel', aspectRatio: 4 / 3 },
  { caption: 'Power', aspectRatio: 4 / 3 },
  { caption: 'NoNo', aspectRatio: 4 / 3 },
  { caption: 'Stop', aspectRatio: 4 / 3 },
  { caption: 'Denying Capitalism', aspectRatio: 3 / 4 },
  { caption: 'Balance', aspectRatio: 4 / 3 },
  { caption: 'Hop', aspectRatio: 4 / 3 },
  { caption: 'Skip', aspectRatio: 4 / 3 },
  { caption: 'TipTop', aspectRatio: 4 / 3 },
  { caption: 'Twirl', aspectRatio: 4 / 3 },
  { caption: 'Exta Kold', aspectRatio: 1 / 1 },
  { caption: 'Screw', aspectRatio: 4 / 3 },
  { caption: 'Stupid', aspectRatio: 4 / 3 },
  { caption: 'I Was Here', aspectRatio: 3 / 4 },
  { caption: 'Alert Strip', aspectRatio: 3 / 4 },
  { caption: 'On/Off', aspectRatio: 3 / 4 },
  { caption: 'No Sitting', aspectRatio: 3 / 4 },
  { caption: 'Misshit' },
  { caption: 'Winks', aspectRatio: 3 / 4 },
  { caption: 'Blueprint', aspectRatio: 4 / 3 },
  { caption: 'Bounding Box', aspectRatio: 4 / 3 },
  { caption: 'Vibrations', aspectRatio: 3 / 4 },
  { caption: 'Speech Bubbles', aspectRatio: 3 / 2 },
  { caption: 'An Impossible Object', aspectRatio: 3 / 4 },
  { caption: 'Terre Mare', aspectRatio: 3 / 4 },
  { caption: 'Iris Print on Greyboard', aspectRatio: 3 / 4 },
  { caption: 'A Certain Ratio', aspectRatio: 3 / 2 },
  { caption: 'Push Push', aspectRatio: 3 / 2 },
  { caption: 'That Which Cannot Be Said', aspectRatio: 4 / 3 },
  { caption: 'Poto & Cabengo', aspectRatio: 3 / 4 },
  { caption: 'Coiling Paper', aspectRatio: 0.851 },
  { caption: 'Base Pad Relief', aspectRatio: 3 / 2 },
  { caption: 'Street Halo', aspectRatio: 3 / 4 },
  { caption: 'Page', aspectRatio: 3 / 4 },
  { caption: 'Comp. w(RBY)', aspectRatio: 3 / 4 },
  { caption: 'Street Scar I', aspectRatio: 3 / 4 },
  { caption: 'Street Scar II', aspectRatio: 3 / 4 },
  { caption: 'Brush', aspectRatio: 1 / 1 },
  { caption: 'A Picture of a View', aspectRatio: 3 / 4 },
  { caption: 'Add', aspectRatio: 4 / 3 },
  { caption: 'Clear', aspectRatio: 3 / 4 },
  { caption: 'A Circular', aspectRatio: 4 / 3 },
  { caption: 'First to Hit', aspectRatio: 3 / 4 },
  { caption: 'Time (Good and Kind)', aspectRatio: 4 / 3 },
  { caption: 'Time (To Do Something)', aspectRatio: 4 / 3 },
  { caption: 'Time (Not For School)', aspectRatio: 3 / 4 },
  { caption: 'Already Done', aspectRatio: 3 / 4 },
  { caption: 'Are You Really', aspectRatio: 4 / 3 },
  { caption: 'Cropped', aspectRatio: 3 / 4 },
  { caption: 'Street Scar III', aspectRatio: 4 / 3 },
  { caption: 'Noisy Doors', aspectRatio: 4 / 3 },
  { caption: 'No Bottle', aspectRatio: 3 / 4 },
  { caption: 'Time (Floor)', aspectRatio: 3 / 4 },
  { caption: 'Locked', aspectRatio: 3 / 4 },
  { caption: 'I-Eye', aspectRatio: 3 / 4 },
  { caption: 'Form Unite', aspectRatio: 1.18 },
  { caption: 'Missing Text', aspectRatio: 4 / 3 },
  { caption: 'Be Visible', aspectRatio: 1 / 1 },
];

export default skeletonData;

import React from 'react';
import { motion } from 'framer-motion';

import '../css/Footer.css';

import CustomLink from '../../../hooks/Global/FadeOutElements/useContentFadeOutOnPageTransition';
import useOpacityHook from '../../../hooks/NavIconScrollOpacity/useOpacityHook';
import { useFooterLinks } from './FooterLinksContext';

const Footer = () => {
  const [opacity] = useOpacityHook();
  const { footerLinks } = useFooterLinks();
  const { prevProjectLink, nextProjectLink, homeLink } = footerLinks;

  const animationProps = (index) => ({
    style: { opacity },
    initial: { y: '100vh' }, // Start from below the viewport
    animate: { y: 0 }, // Animate to its natural position
    exit: { y: '100vh' }, // Exit to below the viewport
    transition: {
      duration: 0.25,
      delay: index * 0.05, // Stagger the animation for a cascading effect
      ease: [0.6, -0.05, 0.01, 0.99], // Custom easing function
    },
  });

  return (
    <div>
      <>
        <motion.div className="arrowleft" {...animationProps(0)}>
          <CustomLink
            className="custom-tooltip no-hover no-underline"
            style={{ backgroundImage: 'none' }}
            to={prevProjectLink}
          >
            <svg viewBox="0 0 25 25" width={70} height={70}>
              <defs>
                <style>
                  {`
                    .cls-1 {
                      fill: none;
                    }
                    .cls-1, .cls-2 {
                      stroke-width: 0px;
                    }
                  `}
                </style>
              </defs>
              <rect className="cls-1" width="25" height="25" />
              <path
                className="cls-2"
                d="M4.18,12.26l5.27,5.27c.44.44,1.15.44,1.59,0s.44-1.15,0-1.59l-3.35-3.35h10.79c.62,0,1.12-.5,1.12-1.12s-.5-1.12-1.12-1.12H7.69l3.35-3.35c.44-.44.44-1.15,0-1.59-.22-.22-.51-.33-.8-.33s-.58.11-.8.33l-5.27,5.27c-.44.44-.44,1.15,0,1.59Z"
              />
            </svg>
            <span
              style={{ left: '35%' }}
              className="tooltip-text tooltip-arrow-left"
            >
              Previous Project
            </span>
          </CustomLink>
        </motion.div>

        <motion.div className="arrowright" {...animationProps(1)}>
          <CustomLink
            className="custom-tooltip no-hover no-underline"
            style={{ backgroundImage: 'none' }}
            to={nextProjectLink}
          >
            <svg viewBox="0 0 25 25" width={70} height={70}>
              <defs>
                <style>
                  {`
                    .cls-1 {
                      fill: none;
                    }
                    .cls-1, .cls-2 {
                      stroke-width: 0px;
                    }
                  `}
                </style>
              </defs>
              <rect className="cls-1" width="25" height="25" />
              <path
                className="cls-2"
                d="M19.27,10.67l-5.27-5.27c-.44-.44-1.15-.44-1.59,0s-.44,1.15,0,1.59l3.35,3.35H4.97c-.62,0-1.12.5-1.12,1.12s.5,1.12,1.12,1.12h10.79l-3.35,3.35c-.44.44-.44,1.15,0,1.59.22.22.51.33.8.33s.58-.11.8-.33l5.27-5.27c.44-.44.44-1.15,0-1.59Z"
              />
            </svg>
            <span className="tooltip-text tooltip-arrow-right">
              Next Project
            </span>
          </CustomLink>
        </motion.div>

        <motion.div className="squarefoliohome" {...animationProps(2)}>
          <CustomLink
            className="custom-tooltip no-hover no-underline"
            style={{ backgroundImage: 'none' }}
            to={homeLink}
          >
            <svg viewBox="0 0 25 25" width={70} height={70}>
              <defs>
                <style>
                  {`
                    .cls-1 {
                      fill: none;
                    }
                    .cls-1, .cls-2 {
                      stroke-width: 0px;
                    }
                  `}
                </style>
              </defs>
              <rect className="cls-1" width="25" height="25" />
              <path
                className="cls-2"
                d="M22.06,8.21L12.95,3.72c-.11-.05-.23-.09-.35-.1-.19-.02-.38.01-.55.1L2.94,8.21c-.34.17-.56.52-.56.9v11.27c0,.55.45,1,1,1h7.11c.55,0,1-.45,1-1v-4.23h2.02v4.23c0,.55.45,1,1,1h7.11c.55,0,1-.45,1-1v-11.27c0-.38-.22-.73-.56-.9ZM10.19,13.9c-.55,0-1,.45-1,1v4.23h-4.51v-9.26l7.82-3.85,7.82,3.85v9.26h-4.51v-4.23c0-.55-.45-1-1-1h-4.62Z"
              />
            </svg>
            <span className="tooltip-text tooltip-tcs">Home</span>
          </CustomLink>
        </motion.div>
      </>
    </div>
  );
};

export default Footer;
import React from 'react';
import { motion } from 'framer-motion';

const Footer = ({ selectedClients, menuItemVariants }) => (
  <motion.div className="footer suspense-toggle" variants={menuItemVariants}>
    <motion.span className="title" variants={menuItemVariants}>
      Selected Clients
    </motion.span>
    <br />
    <br />
    {selectedClients.map((client, index) => (
      <React.Fragment key={index}>
        — {client.name}
        <br />
        {client.location}
        <br />
      </React.Fragment>
    ))}
    <br />
    (c) {new Date().getFullYear()}
  </motion.div>
);

export default Footer;

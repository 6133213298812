import React from 'react';
import { motion } from 'framer-motion';

import CustomLink from '../../../../../hooks/NavMenu/useNavigationLinkWithMenuControl';
import useFadeOut from '../../../../../hooks/NavMenu/useMenuDataMapping';

const MenuItemList = ({ items, closeMenu }) => {
  const visibleItems = useFadeOut(items, closeMenu);

  return (
    <div
      className="responsive-columns"
      style={{ columnGap: '30px', breakInside: 'avoid' }}
    >
      {items.map((item, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 1 }}
          animate={{ opacity: visibleItems[index] ? 1 : 0 }}
          transition={{ duration: 0.5 }}
        >
          {item.link ? (
            <CustomLink closeMenu={closeMenu} to={item.link}>
              {item.name}
            </CustomLink>
          ) : (
            item.name
          )}
        </motion.div>
      ))}
    </div>
  );
};

export default MenuItemList;

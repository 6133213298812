import React, { useEffect, lazy, Suspense, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { FooterLinksProvider, useFooterLinks } from './components/Portfolio/Footer/FooterLinksContext';
import TransitionManager from './hooks/Global/TransitionManager/useTransitionManager';
import usePageLoad from './hooks/Global/PageLoad/usePageLoad';
import CoreScrollToTop from './hooks/Global/CoreScrollPagetoTop/useScrolltoTop';

import NavIcon from './components/Nav/SymbolNav/Container/NavIcon';
import NavStore from './components/Nav/NavStore/NavStore';
import DesktopNav from './components/Nav/DesktopNav/Container/DesktopNav';
import Cookies from './components/Index/Footer/HomeFooter/Cookies';
import Footer from './components/Portfolio/Footer/Footer';
import SkeletonScreen from './components/SkeletonScreens/Index/SkeletonScreen';
import SkeletonScreenFolio from './components/SkeletonScreens/Portfolio/SkeletonScreen';
import ScrollToTop from './components/Index/Footer/ScrolltoTop/ScrolltoTop'; // Ensure correct import

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'intersection-observer';

// Lazy load route components
const Home = lazy(() => import('./pages/Home'));
const TheBeginningofForms = lazy(() => import('./pages/TheBeginningofForms'));
const PublicLecturePosters = lazy(() => import('./pages/PublicLecturePosters'));
const CompanionPlanting = lazy(() => import('./pages/CompanionPlanting'));
const SkeletonArgument = lazy(() => import('./pages/SkeletonArgument'));
const TheToneIsTheirs = lazy(() => import('./pages/TheToneIsTheirs'));
const MarkAndMeasure = lazy(() => import('./pages/Mark&Measure'));
const Penumbra = lazy(() => import('./pages/Penumbra'));
const LanguageWithoutPlace = lazy(() => import('./pages/LanguageWithoutPlace'));
const ForAsFarAsICanSee = lazy(() => import('./pages/ForAsFarAsICanSee'));
const AlphabeticalPareidolia = lazy(() => import('./pages/AlphabeticalPareidolia'));
const MuteWrittenOrchestration = lazy(() => import('./pages/MuteWrittenOrchestration'));
const Light = lazy(() => import('./pages/Light'));
const FoundWhenOut = lazy(() => import('./pages/FoundWhenOut'));
const CombinationPrints = lazy(() => import('./pages/CombinationPrints'));
const SpiritLevel = lazy(() => import('./pages/SpiritLevel'));
const DisplayUnit = lazy(() => import('./pages/DisplayUnit'));
const Titles = lazy(() => import('./pages/Titles'));
const LikeATongueThatTriedToSpeak = lazy(() => import('./pages/LikeATongueThatTriedToSpeak'));
const AZWritingDirections = lazy(() => import('./pages/A-ZWritingDirections'));
const NotesOnAWhitePainting = lazy(() => import('./pages/NotesOnAWhitePainting'));
const Typefaces = lazy(() => import('./pages/Typefaces'));
const TCs = lazy(() => import('./pages/TermsAndConditions'));

const footerLinksMap = {
  '/TheBeginningofForms': { prev: '/NotesOnAWhitePainting', next: '/PublicLecturePosters' },
  '/PublicLecturePosters': { prev: '/TheBeginningofForms', next: '/CompanionPlanting' },
  '/CompanionPlanting': { prev: '/PublicLecturePosters', next: '/SkeletonArgument' },
  '/SkeletonArgument': { prev: '/CompanionPlanting', next: '/TheToneIsTheirs' },
  '/TheToneIsTheirs': { prev: '/SkeletonArgument', next: '/Mark&Measure' },
  '/Mark&Measure': { prev: '/TheToneIsTheirs', next: '/Penumbra' },
  '/Penumbra': { prev: '/Mark&Measure', next: '/LanguageWithoutPlace' },
  '/LanguageWithoutPlace': { prev: '/Penumbra', next: '/ForAsFarAsICanSee' },
  '/ForAsFarAsICanSee': { prev: '/LanguageWithoutPlace', next: '/AlphabeticalPareidolia' },
  '/AlphabeticalPareidolia': { prev: '/ForAsFarAsICanSee', next: '/MuteWrittenOrchestration' },
  '/MuteWrittenOrchestration': { prev: '/AlphabeticalPareidolia', next: '/Light' },
  '/Light': { prev: '/MuteWrittenOrchestration', next: '/FoundWhenOut' },
  '/FoundWhenOut': { prev: '/Light', next: '/CombinationPrints' },
  '/CombinationPrints': { prev: '/FoundWhenOut', next: '/SpiritLevel' },
  '/SpiritLevel': { prev: '/CombinationPrints', next: '/DisplayUnit' },
  '/DisplayUnit': { prev: '/SpiritLevel', next: '/Titles' },
  '/Titles': { prev: '/DisplayUnit', next: '/LikeATongueThatTriedToSpeak' },
  '/LikeATongueThatTriedToSpeak': { prev: '/Titles', next: '/A-ZWritingDirections' },
  '/A-ZWritingDirections': { prev: '/LikeATongueThatTriedToSpeak', next: '/NotesOnAWhitePainting' },
  '/NotesOnAWhitePainting': { prev: '/A-ZWritingDirections', next: '/TheBeginningofForms' },
  '/TermsAndConditions': { prev: '/', next: '/' },
};

const App = () => {
  const location = useLocation();
  const { setFooterLinks } = useFooterLinks();
  const [key, setKey] = useState(0);
  usePageLoad();

  const isHomePage = location.pathname === '/';
  const isTypefacesPage = location.pathname === '/Typefaces';

  useEffect(() => {
    setKey(prev => prev + 1);
    const links = footerLinksMap[location.pathname] || {};
    setFooterLinks({
      prevProjectLink: links.prev || '/',
      nextProjectLink: links.next || '/',
      homeLink: '/'
    });
  }, [location.pathname, setFooterLinks]);

  return (
    <React.Fragment key={key}>
      <CoreScrollToTop />
      <DesktopNav />
      {!isTypefacesPage && <NavIcon />} {/* Conditionally render NavIcon */}
      <NavStore />
      {!isTypefacesPage && <ScrollToTop />} {/* Add ScrollToTop here */}
      {isHomePage && <Cookies />}
      {!isHomePage && !isTypefacesPage && <Footer />}
      <TransitionManager>
        <div id="outer-container">
          <div id="page-wrap">
            <Routes>
              <Route
                path="/"
                element={
                  <Suspense fallback={<SkeletonScreen />}>
                    <Home />
                  </Suspense>
                }
              />
              <Route
                path="/TheBeginningofForms"
                element={
                  <Suspense fallback={<SkeletonScreenFolio />}>
                    <TheBeginningofForms />
                  </Suspense>
                }
              />
              <Route
                path="/PublicLecturePosters"
                element={
                  <Suspense fallback={<SkeletonScreenFolio />}>
                    <PublicLecturePosters />
                  </Suspense>
                }
              />
              <Route
                path="/CompanionPlanting"
                element={
                  <Suspense fallback={<SkeletonScreenFolio />}>
                    <CompanionPlanting />
                  </Suspense>
                }
              />
              <Route
                path="/SkeletonArgument"
                element={
                  <Suspense fallback={<SkeletonScreenFolio />}>
                    <SkeletonArgument />
                  </Suspense>
                }
              />
              <Route
                path="/TheToneIsTheirs"
                element={
                  <Suspense fallback={<SkeletonScreenFolio />}>
                    <TheToneIsTheirs />
                  </Suspense>
                }
              />
              <Route
                path="/Mark&Measure"
                element={
                  <Suspense fallback={<SkeletonScreenFolio />}>
                    <MarkAndMeasure />
                  </Suspense>
                }
              />
              <Route
                path="/Penumbra"
                element={
                  <Suspense fallback={<SkeletonScreenFolio />}>
                    <Penumbra />
                  </Suspense>
                }
              />
              <Route
                path="/LanguageWithoutPlace"
                element={
                  <Suspense fallback={<SkeletonScreenFolio />}>
                    <LanguageWithoutPlace />
                  </Suspense>
                }
              />
              <Route
                path="/ForAsFarAsICanSee"
                element={
                  <Suspense fallback={<SkeletonScreenFolio />}>
                    <ForAsFarAsICanSee />
                  </Suspense>
                }
              />
              <Route
                path="/AlphabeticalPareidolia"
                element={
                  <Suspense fallback={<SkeletonScreenFolio />}>
                    <AlphabeticalPareidolia />
                  </Suspense>
                }
              />
              <Route
                path="/MuteWrittenOrchestration"
                element={
                  <Suspense fallback={<SkeletonScreenFolio />}>
                    <MuteWrittenOrchestration />
                  </Suspense>
                }
              />
              <Route
                path="/Light"
                element={
                  <Suspense fallback={<SkeletonScreenFolio />}>
                    <Light />
                  </Suspense>
                }
              />
              <Route
                path="/FoundWhenOut"
                element={
                  <Suspense fallback={<SkeletonScreenFolio />}>
                    <FoundWhenOut />
                  </Suspense>
                }
              />
              <Route
                path="/CombinationPrints"
                element={
                  <Suspense fallback={<SkeletonScreenFolio />}>
                    <CombinationPrints />
                  </Suspense>
                }
              />
              <Route
                path="/SpiritLevel"
                element={
                  <Suspense fallback={<SkeletonScreenFolio />}>
                    <SpiritLevel />
                  </Suspense>
                }
              />
              <Route
                path="/DisplayUnit"
                element={
                  <Suspense fallback={<SkeletonScreenFolio />}>
                    <DisplayUnit />
                  </Suspense>
                }
              />
              <Route
                path="/Titles"
                element={
                  <Suspense fallback={<SkeletonScreenFolio />}>
                    <Titles />
                  </Suspense>
                }
              />
              <Route
                path="/LikeATongueThatTriedToSpeak"
                element={
                  <Suspense fallback={<SkeletonScreenFolio />}>
                    <LikeATongueThatTriedToSpeak />
                  </Suspense>
                }
              />
              <Route
                path="/A-ZWritingDirections"
                element={
                  <Suspense fallback={<SkeletonScreenFolio />}>
                    <AZWritingDirections />
                  </Suspense>
                }
              />
              <Route
                path="/NotesOnAWhitePainting"
                element={
                  <Suspense fallback={<SkeletonScreenFolio />}>
                    <NotesOnAWhitePainting />
                  </Suspense>
                }
              />
              <Route
                path="/Typefaces"
                element={
                  <Suspense fallback={<SkeletonScreenFolio />}>
                    <Typefaces />
                  </Suspense>
                }
              />
              <Route
                path="/TermsAndConditions"
                element={
                  <Suspense fallback={<SkeletonScreenFolio />}>
                    <TCs />
                  </Suspense>
                }
              />
            </Routes>
          </div>
        </div>
      </TransitionManager>
    </React.Fragment>
  );
};

const AppWrapper = () => (
  <HelmetProvider>
    <Router>
      <FooterLinksProvider>
        <App />
      </FooterLinksProvider>
    </Router>
  </HelmetProvider>
);

export default AppWrapper;
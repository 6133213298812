// CustomLink.js
import React from 'react';
import { Link } from 'react-router-dom';
import withFadeOut from './useFadeOutContent';

const CustomLink = ({ to, children, closeMenu, ...props }) => {
  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};

export default withFadeOut(CustomLink);

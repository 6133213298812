const Styles = {
  navContainer: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    zIndex: 3000,
    backgroundColor: 'none',
  },
  modalOverlay: {
    zIndex: 3000,
    backgroundColor: 'rgba(0, 0, 0, 0.2)', // Transparent background
    backdropFilter: 'blur(6px)', // Apply backdrop blur effect
    WebkitBackdropFilter: 'blur(6px)', // Safari
    MozBackdropFilter: 'blur(6px)', // Firefox (not officially supported, but added for completeness)
    MsBackdropFilter: 'blur(6px)', // IE (not officially supported, but added for completeness)
    OBackdropFilter: 'blur(6px)', // Opera (not officially supported, but added for completeness)
  },
  modalContent: {
    position: 'relative',
    zIndex: 1100,
    background: 'rgb(16,12,8)',
    color: 'white',
    borderRadius: '0.6rem',
    boxShadow: '10px 10px 15px rgba(0, 0, 0, 0.8)',
    padding: '30px 30px 30px 30px',
    maxWidth: '60vw',
    minWidth: '60vw',
    maxHeight: '70.25vh',
    width: '100%',
    fontFamily: 'Jant, sans-serif',
    hyphens: 'none',
    wordBreak: 'normal',
    overflowWrap: 'normal',
    overflow: 'hidden', // Ensure no internal scrollbar
    scrollbarWidth: 'none', // For Firefox
    msOverflowStyle: 'none', // For Internet Explorer and Edge
    WebkitOverflowScrolling: 'touch', // Smooth scrolling for Safari
  },
  linkText: (opacity) => ({
    backgroundColor: 'rgb(44,255,5)',
    color: 'rgb(16,12,8)',
    padding: '6px 18px 4px 18px',
    borderRadius: '50px',
    display: 'inline-block',
    opacity: opacity,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    wordBreak: 'normal',
    overflowWrap: 'normal',
  }),
};

export default Styles;
import React from 'react';
import { motion } from 'framer-motion';

const Profile = ({ about, menuItemVariants }) => (
  <motion.div className="profile suspense-toggle" variants={menuItemVariants}>
    <motion.span className="title" variants={menuItemVariants}>
      {about.title}
    </motion.span>
    <br />
    <br />
    {about.content.split('\n\n').map((paragraph, index) => (
      <React.Fragment key={index}>
        {paragraph}
        <br />
        <br />
      </React.Fragment>
    ))}
  </motion.div>
);

export default Profile;

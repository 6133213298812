import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageLoad = () => {
  const location = useLocation();

  useEffect(() => {
    const handleLoad = () => {
      document.body.style.visibility = 'visible';
      document.body.style.opacity = '1';
    };

    const handleScrollRestoration = () => {
      if (typeof window.history.scrollRestoration !== 'undefined') {
        window.history.scrollRestoration = 'manual';
      }
    };

    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    };

    const loadPage = async () => {
      try {
        // Simulate an async operation for the page, excluding .outlet
        await new Promise((resolve) => {
          const elements = document.querySelectorAll(':not(.outlet)');
          setTimeout(() => {
            elements.forEach((el) => {
              // Perform any necessary operations on non-outlet elements
            });
            resolve();
          }, 0);
        });
      } catch (error) {
        console.error('Failed to load page', error);
      }
    };

    window.addEventListener('load', handleLoad);
    window.addEventListener('load', handleScrollToTop);
    handleScrollRestoration();
    loadPage();

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('load', handleLoad);
      window.removeEventListener('load', handleScrollToTop);
    };
  }, [location]);

  useEffect(() => {
    // Add custom-body class to body for all routes except /Typefaces
    if (location.pathname !== '/Typefaces') {
      document.body.classList.add('body');
    } else {
      document.body.classList.remove('body');
    }
  }, [location.pathname]);
};

export default usePageLoad;

import React, { useEffect, useCallback, useState } from 'react';
import '../css/ScrolltoTop.css';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const checkScrollPosition = useCallback(() => {
    const scrolled = window.pageYOffset;
    setIsVisible(scrolled > 300); // Show button when scrolled down 300px
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', checkScrollPosition);
    return () => window.removeEventListener('scroll', checkScrollPosition);
  }, [checkScrollPosition]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  if (!isVisible) {
    return null;
  }

  return (
    <button
      className="return-to-top"
      onClick={scrollToTop}
      style={{ 
        cursor: 'pointer', 
        background: 'none', 
        border: 'none', 
        padding: 0,
        position: 'fixed',
        bottom: '38px',
        right: '30px',
        zIndex: 1
      }}
      aria-label="Scroll to top"
    >
      <svg
        id="a"
        xmlns="http://www.w3.org/2000/svg"
        width="23.99"
        height="23.99"
        viewBox="0 0 23.99 23.99"
        style={{ width: '60px', height: '60px' }}
      >
        <path d="M11.99,2.4C6.7,2.4,2.4,6.7,2.4,11.99s4.3,9.59,9.59,9.59,9.59-4.3,9.59-9.59S17.28,2.4,11.99,2.4ZM16.44,14.4c-.41.42-1.15.42-1.57,0l-1.77-1.77v6.08c0,.61-.5,1.11-1.11,1.11s-1.11-.5-1.11-1.11v-6.08l-1.77,1.77c-.21.21-.49.32-.78.33h0c-.3,0-.58-.12-.79-.33-.43-.43-.43-1.13,0-1.57l3.66-3.66c.43-.43,1.13-.43,1.57,0l3.66,3.66c.21.21.33.49.33.79s-.12.58-.33.79ZM16.26,7.29H7.72c-.61,0-1.1-.49-1.1-1.1s.49-1.1,1.1-1.1h8.54c.61,0,1.1.49,1.1,1.1s-.49,1.1-1.1,1.1Z" fill="none" strokeWidth="0"/>
        <path d="M11.99,0C5.38,0,0,5.38,0,11.99s5.38,11.99,11.99,11.99,11.99-5.38,11.99-11.99S18.61,0,11.99,0ZM11.99,21.59c-5.29,0-9.59-4.3-9.59-9.59S6.7,2.4,11.99,2.4s9.59,4.3,9.59,9.59-4.3,9.59-9.59,9.59Z" strokeWidth="0"/>
        <path d="M12.77,9.16c-.43-.43-1.13-.43-1.57,0l-3.66,3.66c-.43.43-.43,1.13,0,1.57.21.21.49.33.79.33h0c.3,0,.57-.12.78-.33l1.77-1.77v6.08c0,.61.5,1.11,1.11,1.11s1.11-.5,1.11-1.11v-6.08l1.77,1.77c.42.42,1.15.42,1.57,0,.21-.21.33-.49.33-.79s-.12-.58-.33-.79l-3.66-3.66Z" strokeWidth="0"/>
        <path d="M16.26,5.09H7.72c-.61,0-1.1.49-1.1,1.1s.49,1.1,1.1,1.1h8.54c.61,0,1.1-.49,1.1-1.1s-.49-1.1-1.1-1.1Z" strokeWidth="0"/>
      </svg>
    </button>
  );
};

export default ScrollToTop;
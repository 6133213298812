import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet

const AboutSection = ({ aboutData, clientsData }) => {
  useEffect(() => {
    // Update Helmet when the component mounts
    document.title = 'About - Scott Joseph Studio';
    const metaDescriptionTag = document.querySelector(
      'meta[name="description"]'
    );
    if (metaDescriptionTag) {
      metaDescriptionTag.content = 'Learn more About Scott Joseph Studio';
    }

    // Cleanup function to restore previous title and meta description
    return () => {
      document.title = 'Scott Joseph Studio';
      if (metaDescriptionTag) {
        metaDescriptionTag.content = 'Scott Joseph Studio';
      }
    };
  }, []);

  return (
    <div className="modal-content">
      <Helmet>
        <title>About - Scott Joseph Studio</title>
        <meta name="description" content="About - Scott Joseph Studio" />
      </Helmet>
      <div className="title">About</div>
      <hr />
      <div
        className="responsive-columns-about"
        style={{ columnGap: '30px', breakInside: 'avoid' }}
      >
        {aboutData.content.split('\n\n').map((paragraph, index) => (
          <React.Fragment key={index}>
            <p>{paragraph}</p>
            <br />
          </React.Fragment>
        ))}
      </div>
      <hr />
      <div className="title">Selected Clients</div>
      <br />
      <div
        className="responsive-columns-client"
        style={{ columnGap: '30px', breakInside: 'avoid' }}
      >
        {clientsData.map((client, index) => (
          <div key={index}>
            — {client.name}, {client.location}
          </div>
        ))}
        <br />
        (c) {new Date().getFullYear()}
      </div>
      <hr className="no-margin" />
    </div>
  );
};

export default AboutSection;

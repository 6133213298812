import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet
import emailjs from 'emailjs-com';
import { supabase } from '../../../../Supabase/Supabase'; // Import Supabase
import CustomMenuLink from '../../../../../hooks/NavMenu/useNavigationLinkWithMenuControl'; // Correct import for CustomMenuLink

const ContactSection = ({ contactData, closeMenu }) => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    // Update Helmet when the component mounts
    document.title = 'Contact - Scott Joseph Studio';
    const metaDescriptionTag = document.querySelector(
      'meta[name="description"]'
    );
    if (metaDescriptionTag) {
      metaDescriptionTag.content = 'Contact - Scott Joseph Studio';
    }

    // Cleanup function to restore previous title and meta description
    return () => {
      document.title = 'Scott Joseph Studio';
      if (metaDescriptionTag) {
        metaDescriptionTag.content = 'Scott Joseph Studio';
      }
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendEmailNotification(email); // Send email notification
      await logEmail(email); // Log the email to Supabase
      closeMenu(); // Close the menu
    } catch (error) {
      console.error('Failed to send email.', error);
      closeMenu(); // Close the menu even if there's an error
    }
    setEmail(''); // Clear the email input field
  };

  const sendEmailNotification = async (email) => {
    const templateParams = {
      to_email: contactData.email,
      from_email: email,
      message: `Email: ${email}`,
    };

    await emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID, // Use environment variable
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID, // Use environment variable
      templateParams,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY // Use environment variable
    );
  };

  // Log the email to Supabase
  const logEmail = async (email) => {
    console.log('Logging email:', email);
    const { error } = await supabase
      .from('subscribers')
      .insert([{ emails_of_subscribers: email }]);
    if (error) {
      console.error('Error logging email:', error);
      throw error;
    }
    console.log('Email logged successfully');
  };

  return (
    <div className="modal-content">
      <Helmet>
        <title>Contact - Scott Joseph Studio</title>
        <meta
          name="description"
          content="Get in touch with Scott Joseph Studio"
        />
      </Helmet>
      <div className="title">Contact</div>
      <hr />
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ flex: 1, padding: '0px 0px 0px', marginLeft: '0px' }}>
          <div
            className="responsive-columns-contact"
            style={{ columnGap: '30px' }}
          >
            <div className="title">Scott Joseph Studio</div>
            <br />
            <div>
              {contactData.address.street}
              <p></p>
              {contactData.address.studio}
              <p></p>
              {contactData.address.city}
              <p></p>
              {contactData.address.postcode}
            </div>
            <br />
            <br />
            <div>
              <CustomMenuLink
                to={`mailto:${contactData.email}`}
                closeMenu={closeMenu}
              >
                <span>{contactData.email}</span>
              </CustomMenuLink>
            </div>
            <div>
              <CustomMenuLink to="TermsAndConditions" closeMenu={closeMenu}>
                Terms and Conditions
              </CustomMenuLink>
            </div>
            <br />
            <div className="title mb-4">Newsletter</div>
            <form onSubmit={handleSubmit}>
              <label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="neon-placeholder contact-input"
                  placeholder="Enter email"
                  required
                />
              </label>
              <br />
              <button type="submit" className="subscribe-button">
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
      <hr className="no-margin" />
    </div>
  );
};

export default ContactSection;

import { useState, useEffect } from 'react';

const useResponsiveMenuState = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const [wasOpenBeforeResize, setWasOpenBeforeResize] = useState(false);

  const handleStateChange = (state) => {
    setIsOpen(state.isOpen);
    if (state.isOpen) {
      setTimeout(() => {
        const menuContent = document.querySelector('.bm-menu');
        if (menuContent) {
          menuContent.scrollTop = 0;
        }
      }, 0);
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setIsMenuVisible(true);
        if (isOpen) {
          setWasOpenBeforeResize(true);
          setIsOpen(false);
        }
      } else {
        setIsMenuVisible(false);
        if (wasOpenBeforeResize) {
          setIsOpen(true);
          setWasOpenBeforeResize(false);
        }
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpen, wasOpenBeforeResize]);

  return { isOpen, isMenuVisible, setIsOpen, handleStateChange, closeMenu };
};

export default useResponsiveMenuState;

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet
import styled from 'styled-components';
import MenuItemList from '../Container/MenuItemList';

// Utility function to detect Safari
const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

// Styled <hr> component
const StyledHr = styled.hr`
  &:last-of-type {
    margin-bottom: 0px; /* Remove margin-bottom for the last <hr> */
  }
`;

const ProjectsSection = ({ menuData, closeMenu }) => {
  useEffect(() => {
    // Update Helmet when the component mounts
    document.title = 'Projects - Scott Joseph Studio';
    const metaDescriptionTag = document.querySelector(
      'meta[name="description"]'
    );
    if (metaDescriptionTag) {
      metaDescriptionTag.content = 'About - Scott Joseph Studio';
    }

    // Cleanup function to restore previous title and meta description
    return () => {
      document.title = 'Scott Joseph Studio';
      if (metaDescriptionTag) {
        metaDescriptionTag.content = 'Scott Joseph Studio';
      }
    };
  }, []);

  return (
    <div className="modal-content no-scroll">
      <Helmet>
        <title>Projects - Scott Joseph Studio</title>
        <meta
          name="description"
          content="Explore Projects at Scott Joseph Studio"
        />
      </Helmet>
      <div className="title">Projects</div>
      <div className="content-wrapper">
        {menuData.sections.map((section, index) => (
          <React.Fragment key={index}>
            {index === 0 && <StyledHr />}
            <div className="title">{section.title}</div>
            <br />
            <MenuItemList items={section.items} closeMenu={closeMenu} />
            {isSafari() && <br />}
            <StyledHr />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default ProjectsSection;

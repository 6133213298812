import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ModalContent from './ModalContent';

const Modal = ({
  activeModal,
  setActiveModal,
  modalOverlayStyle,
  modalContentStyleWebkit,
  modalContentClass,
}) => {
  const blurStyle = {
    position: 'absolute',
    left: 0,
    right: 0,
    height: '30px',
    pointerEvents: 'none',
    zIndex: 1,
  };

  const topBlurStyle = {
    ...blurStyle,
    top: 0,
    background: 'linear-gradient(to bottom, rgb(16,12,8) 0%, rgba(16,12,8,0) 100%)',
  };

  const bottomBlurStyle = {
    ...blurStyle,
    bottom: 0,
    background: 'linear-gradient(to top, rgb(16,12,8) 0%, rgba(16,12,8,0) 100%)',
  };

  const contentStyle = {
    overflowY: 'scroll',
    overflowX: 'hidden',
    flex: 1,
    paddingTop: '20px',
    paddingBottom: '20px',
    marginTop: '-20px',
    marginBottom: '-20px',
    WebkitOverflowScrolling: 'touch',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  };

  return (
    <AnimatePresence>
      {activeModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.25, ease: 'easeInOut' }}
          className="fixed inset-0 flex items-center justify-center"
          style={{
            ...modalOverlayStyle,
            cursor: "url(\"data:image/svg+xml,%3Csvg%20id='Layer_1'%20xmlns='http://www.w3.org/2000/svg'%20width='34'%20height='34'%20viewBox='0%200%2013.21%2013.21'%3E%3Cpath%20d='M12.88,11.29l-4.69-4.69L12.88,1.92c.44-.44.44-1.15,0-1.59s-1.15-.44-1.59,0l-4.69,4.69L1.92.33C1.48-.11.77-.11.33.33s-.44,1.15,0,1.59l4.69,4.69L.33,11.29c-.44.44-.44,1.15,0,1.59s1.15.44,1.59,0l4.69-4.69,4.69,4.69c.44.44,1.15.44,1.59,0s.44-1.15,0-1.59Z'%20fill='%23b026ff'%20stroke-width='0'/%3E%3C/svg%3E\") 16 16, pointer",
          }}
          onClick={() => setActiveModal(null)}
        >
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0, opacity: 0 }}
            transition={{ duration: 0.25, ease: 'easeInOut' }}
            className={`modal-content ${modalContentClass}`}
            style={{
              ...modalContentStyleWebkit,
              position: 'relative',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div style={topBlurStyle} />
            <div style={contentStyle} className="safari-scrollbar-fix">
              <ModalContent
                modal={activeModal}
                closeMenu={() => setActiveModal(null)}
              />
            </div>
            <div style={bottomBlurStyle} />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
import React from 'react';
import { useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import CoreScrollToTop from '../CoreScrollPagetoTop/useScrolltoTop'; // Renamed to CoreScrollToTop

const TransitionManager = ({ children }) => {
  const location = useLocation();

  return (
    <AnimatePresence mode="sync"> {/* Changed mode to "sync" */}
      <CoreScrollToTop key="core-scroll-to-top" /> {/* Updated key */}
      <motion.div
        key={location.pathname}
        initial={{ opacity: 1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
        transition={{
          duration: 0.25,
          ease: [0.6, -0.05, 0.01, 0.99],
          delay: 0.1,
        }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default TransitionManager;
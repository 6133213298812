const menuData = {
  studioName: 'Scott Joseph Studio',
  sections: [
    {
      title: 'Printed Matter',
      items: [
        {
          name: 'The Beginning of Forms',
          link: '/TheBeginningofForms',
        },
        { name: 'Public Lecture Series', link: '/PublicLecturePosters' },
        { name: 'Companion Planting', link: '/CompanionPlanting' },
        { name: 'Skeleton Argument', link: '/SkeletonArgument' },
        { name: 'The Tone Is Theirs', link: '/TheToneIsTheirs' },
        { name: 'Mark & Measure', link: '/Mark&Measure' },
      ],
    },
    {
      title: 'Image',
      items: [
        { name: 'Penumbra', link: '/Penumbra' },
        { name: 'Language Without Place', link: '/LanguageWithoutPlace' },
        {
          name: 'For As Far As I Can See',
          link: '/ForAsFarAsICanSee',
          className: 'break-before-see',
        },
        { name: 'Alphabetical Pareidolia', link: '/AlphabeticalPareidolia' },
        {
          name: 'Mute Written Orchestration',
          link: '/MuteWrittenOrchestration',
        },
        { name: 'Light', link: '/Light' },
      ],
    },
    {
      title: 'Object',
      items: [
        { name: 'Found When Out I', link: '/FoundWhenOut' },
        { name: 'Combination Prints', link: '/CombinationPrints' },
        { name: 'Spirit Level', link: '/SpiritLevel' },
        { name: 'Display Unit', link: '/DisplayUnit' },
      ],
    },
    {
      title: 'Typography',
      items: [
        { name: 'Speech Flies Away, Written Words Remain', link: '/Titles' },
        {
          name: 'Like A Tongue That Tried To Speak',
          link: '/LikeATongueThatTriedToSpeak',
          className: 'break-before-speak',
        },
        { name: 'A-Z Directions', link: '/A-ZWritingDirections' },
        { name: 'Notes On A White Painting', link: 'NotesOnAWhitePainting' },
      ],
    },
  ],
  about: {
    title: 'About',
    content:
      "Scott Joseph Studio is an independent design practice specialising in editorial and communication design, visual identities, and type design.\n\nCentral to the studio approach are practice based visual design principles which emphasise content as a premise for the creation of form, using the conditions and contexts of a brief, alongside stakeholder dialogue, to develop bespoke representations of a process and its material outcome.\n\nThe studio has publications it's designed held in the public collections of the Museum of Modern Art, NYC and Stedelijk Museum, Amsterdam, and has been profiled in AIGA magazine, NYC, US and IDEA magazine, Tokyo, JP.\n\nJoseph is a Gerrit Rietveld Academy alumni and has represented the UK at the International Biennale for Graphic Design in Brno, CZ, in both 2012 and 2016, and was a designer in residence in 2017 at Rogaland Kunstsenter, Stavanger, NO, and has held posts as Lecturer/Senior Lecturer in Graphic and Communication Design at Foundation, Undergraduate and Post Graduate level, on a fractional and full time basis, including at: University for the Creative Arts, UK (2014); University of East London, UK (2015); ArtEZ Institute of the Arts, Arnhem, NL (2016); Arts University Bournemouth, UK (2017); Bucks New University, UK (2018) and University of Greenwich, UK (2019–21).\n\nCurrent stack: Adobe, Glyphs, Figma, CSS, React JS, Next JS, NPM, Express JS, Postgres, Github, AI.\n\nThis website is typeset in R1 Regular and is programmed in React JS.",
  },
  selectedClients: [
    { name: 'AIGA, Eye on Design magazine', location: 'New York, US' },
    { name: 'IDEA magazine', location: 'Tokyo, JP' },
    { name: 'Goldsmiths, UoL', location: 'London, UK' },
    { name: 'Wellcome Collection', location: 'London, UK' },
    { name: 'Rogaland Kunstsenter', location: 'Stavanger, NO' },
    { name: 'Pump House Gallery', location: 'London, UK' },
    { name: 'Goethe Institute', location: 'Amsterdam, NL' },
    { name: 'Marres, Centre for Contemporary Art', location: 'Maastricht, NL' },
    { name: 'Moravian Gallery', location: 'Brno, CZ' },
    { name: 'Pakt', location: 'Amsterdam, NL' },
    { name: 'Perdu Literary Foundation', location: 'Amsterdam, NL' },
    { name: 'Nieuwe Vide', location: 'Haarlem, NL' },
    { name: 'Felicia von Zweigbergk', location: 'Amsterdam, NL' },
    { name: 'Radna Rumping', location: 'Amsterdam, NL' },
    { name: 'Josh Bitelli', location: 'London, UK' },
    { name: 'Gerrit Rietveld Academie', location: 'Amsterdam, NL' },
    { name: 'Fondskwadraat', location: 'Amsterdam, NL' },
    { name: 'Fonds BKVB', location: 'Amsterdam, NL' },
    { name: 'Amsterdam Fonds for the Arts', location: 'Amsterdam, NL' },
    { name: 'Norsk Kulturfond', location: 'Oslo, NO' },
    { name: 'Stimulerings Fonds', location: 'Rotterdam, NL' },
  ],
  contact: {
    studioName: 'Contact',
    address: {
      street: '1 Cardwell Terrace',
      studio: 'Studio 80',
      city: 'London',
      postcode: 'N7 0NH',
    },
    email: 'info@scottpauljoseph.com',
    phone: '+44 2034902785',
  },
  links: [
    { name: 'Home', link: '/' },
    { name: 'Terms and Conditions', link: '/TermsAndConditions' },
  ],
};

export default menuData;

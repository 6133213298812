import React from 'react';
import { motion } from 'framer-motion';

import Styles from '../../Container/Styles';

import ProjectsSection from '../Sections/ProjectsSection';
import AboutSection from '../Sections/AboutSection';
import ContactSection from '../Sections/ContactSection';

import menuData from '../../../MenuData/MenuData';

const ModalContent = ({ modal, closeMenu }) => {
  const menuContent = {
    hidden: { opacity: 0, y: 0 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.2,
        ease: 'easeInOut',
        staggerChildren: 0.2,
        when: 'beforeChildren',
      },
    },
    exit: {
      opacity: 0,
      y: 0,
      transition: {
        duration: 0.2,
        ease: 'easeInOut',
        staggerChildren: 0.1,
        staggerDirection: -1,
        when: 'afterChildren',
      },
    },
  };

  const getModalContent = (modal) => {
    switch (modal) {
      case 'Projects':
        return <ProjectsSection menuData={menuData} closeMenu={closeMenu} />;
      case 'About':
        return (
          <AboutSection
            aboutData={menuData.about}
            clientsData={menuData.selectedClients}
            closeMenu={closeMenu}
          />
        );
      case 'Contact':
        return (
          <ContactSection
            contactData={menuData.contact}
            closeMenu={closeMenu}
          />
        );
      default:
        return null;
    }
  };

  return (
    <motion.div
      variants={menuContent}
      initial="visible"
      animate="visible"
      exit="exit"
    >
      {getModalContent(modal)}
    </motion.div>
  );
};

export default ModalContent;

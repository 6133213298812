import React from 'react';
import { motion } from 'framer-motion';

import '../css/SkeletonScreenFolio.css'; // Import the custom CSS

import { ReactComponent as Coin } from '../../../assets/animations/Render.svg'; // Adjust the path as needed

const SkeletonScreenFolio = () => {
  const containerVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.2, delay: 0.05 },
    },
    exit: { opacity: 0, scale: 0.0, transition: { duration: 0.2 } },
  };

  const coinVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.2, delay: 0.05 },
    },
    exit: { opacity: 0, scale: 0, transition: { duration: 0.2 } },
  };

  return (
    <motion.div
      className="shape-container"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <motion.div className="coin" variants={coinVariants}>
        <Coin />
      </motion.div>
    </motion.div>
  );
};

export default React.memo(SkeletonScreenFolio);

import { useEffect, useRef } from 'react';
import useOpacityHook from '../NavIconScrollOpacity/useOpacityHook';
import useMenuState from './useResponsiveMenuState';

const useNavIconState = (setIsMenuOpen) => {
  const [opacity] = useOpacityHook();
  const { isOpen, setIsOpen, handleStateChange } = useMenuState();
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('menu-open');
      document.body.classList.add('hide-pseudo-elements');
    } else {
      // Clear any existing timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      
      // Set a new timeout
      timeoutRef.current = setTimeout(() => {
        document.body.classList.remove('menu-open');
        document.body.classList.remove('hide-pseudo-elements');
      }, 630); // Adjust this delay to match your menu closing animation duration
    }

    // Cleanup function
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isOpen]);

  const handleMenuOpen = () => {
    setIsOpen(true);
    if (setIsMenuOpen) setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsOpen(false);
    if (setIsMenuOpen) setIsMenuOpen(false);
  };

  const handleLinkClick = () => {
    // Keep the pseudo-elements hidden during navigation
    document.body.classList.add('hide-pseudo-elements');
    handleMenuClose();
  };

  return {
    opacity,
    isOpen,
    handleStateChange,
    handleMenuOpen,
    handleMenuClose,
    handleLinkClick,
  };
};

export default useNavIconState;
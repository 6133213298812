import { motion, AnimatePresence } from 'framer-motion';

import '../css/Cookies.css';

import CustomCloseIcon from './CustomCloseIcon';
import useCookieConsent from '../../../../hooks/Cookies/useCookieConsent';

const Cookies = () => {
  const { showBanner, handleAccept, handleClose } = useCookieConsent();

  return (
    <AnimatePresence>
      {showBanner && (
        <motion.div
          id="cookieConsent"
          initial={{ bottom: '-100px', opacity: 0 }}
          animate={{ bottom: '30px', opacity: 1 }}
          exit={{ bottom: '-100px', opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <p className="cookieConsentOK" onClick={handleAccept}>
            Accept Cookies
          </p>
          <div id="closeCookieConsent" onClick={handleClose}>
            <CustomCloseIcon />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Cookies;

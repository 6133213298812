import React from 'react';
import { useNavigate } from 'react-router-dom';

const withFadeOut = (WrappedComponent) => {
  return ({ to, children, closeMenu, ...props }) => {
    const navigate = useNavigate();

    const handleClick = (e) => {
      e.preventDefault();

      // Apply the fade out animation to elements
      const projectDescriptions = document.querySelectorAll('.project-description');
      const sliderWrappers = document.querySelectorAll('.slider-wrapper');
      const sliderBoxes = document.querySelectorAll('.slider-box');
      const gridItems = document.querySelectorAll('.grid-item');
      const elementsToFadeNormal = document.querySelectorAll(
        '.arrowleft, .arrowright, .squarefoliohome, #cookieConsent, #return-to-top'
      );

      // Check media queries
      const isBetween1200And1580 = window.matchMedia('(min-width: 1200px) and (max-width: 1580px)').matches;

      const fadeOutElements = (elements, delayFactor) => {
        elements.forEach((element, index) => {
          element.style.transition = `opacity 0.2s ease ${index * delayFactor}s`;
          element.style.opacity = 0;
        });
      };

      if (isBetween1200And1580) {
        // Order: slider-wrapper, slider-box, project-description
        fadeOutElements(sliderWrappers, 0.1);
        fadeOutElements(sliderBoxes, 0.1);
        fadeOutElements(projectDescriptions, 0.3);
      } else {
        // Order: project-description, slider-wrapper, slider-box
        fadeOutElements(projectDescriptions, 0.1);
        fadeOutElements(sliderWrappers, 0.1);
        fadeOutElements(sliderBoxes, 0.3);
      }

      // Fade out grid items in standard order
      fadeOutElements(gridItems, 0.1);

      // Fade out other elements
      elementsToFadeNormal.forEach((element, index) => {
        element.style.transition = `opacity 0.1s ease ${index * 0.1}s`;
        element.style.opacity = 0;
      });

      // Wait for animations to complete before navigating
      setTimeout(() => {
        navigate(to);
        if (closeMenu) closeMenu();
      }, 200); // Adjust timeout based on the longest animation duration
    };

    return (
      <WrappedComponent to={to} onClick={handleClick} {...props}>
        {children}
      </WrappedComponent>
    );
  };
};

export default withFadeOut;
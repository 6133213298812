import React from 'react';
import Cookies from '../Cookies/Cookies';// Ensure correct import

const HomeFooter = () => {
  return (

      <Cookies />
    
  );
};

export default HomeFooter;
import React, { useEffect, useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';

import '../../css/NavIcon.css';
import '../../css/NavGrid.css';
import PlusSign from '../../../../assets/animations/PlusSign.svg';

import Projects from '../Sections/Projects';
import Profile from '../Sections/Profile';
import Footer from '../Sections/Footer';
import Contact from '../Sections/Contact';

import menuData from '../../MenuData/MenuData';

import CloseMenuLink from '../../../../hooks/NavMenu/useNavigationLinkWithMenuControl';
import useNavIcon from '../../../../hooks/NavMenu/useNavIconState';
import useBodyScrollLock from '../../../../hooks/NavMenu/useBodyScrollLock';

const menuItemVariants = {
  hidden: {
    opacity: 0,
    x: -100,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.1,
    },
  },
  exit: {
    opacity: 0,
    y: 0,
    transition: {
      duration: 0.05,
    },
  },
};

const menuContentVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.1,
      ease: 'easeInOut',
      staggerChildren: 0,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.05,
      ease: 'easeInOut',
      staggerChildren: 0.05,
      staggerDirection: -1,
      when: 'afterChildren',
    },
  },
};

const NavIcon = ({ setIsMenuOpen }) => {
  const {
    opacity,
    isOpen,
    handleStateChange,
    handleMenuOpen,
    handleMenuClose,
  } = useNavIcon(setIsMenuOpen);

  useBodyScrollLock(isOpen);

  const location = useLocation();

  // State to track previous title and meta description
  const [prevTitle, setPrevTitle] = useState('');
  const [prevMetaDescription, setPrevMetaDescription] = useState('');

  // State to detect if the device is mobile
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Save the current title and meta description when the component mounts
    setPrevTitle(document.title);
    const metaDescriptionTag = document.querySelector(
      'meta[name="description"]'
    );
    setPrevMetaDescription(
      metaDescriptionTag ? metaDescriptionTag.content : ''
    );

    // Detect if the device is mobile
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener('resize', handleResize);
  }, [location.pathname]); // Add location.pathname as a dependency to update on route change

  useEffect(() => {
    if (isOpen) {
      // Update Helmet when the menu opens
      document.title = 'Menu - Scott Joseph Studio';
      const metaDescriptionTag = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = 'Projects, About, Clients, Contact';
      }
      document.body.classList.add('hide-pseudo-elements');
    } else {
      // Restore previous title and meta description when the menu closes
      document.title = prevTitle;
      const metaDescriptionTag = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = prevMetaDescription;
      }
      setTimeout(() => {
        document.body.classList.remove('hide-pseudo-elements');
      }, 300); // Adjust the delay to match your animation duration
    }
  }, [isOpen, prevTitle, prevMetaDescription]);

  const hrElement = (
    <motion.div variants={menuContentVariants}>
      <hr />
    </motion.div>
  );

  const specialHrElement = (
    <motion.div variants={menuContentVariants}>
      <hr className="special-hr" />
    </motion.div>
  );

  const isSafari = document.body.classList.contains('safari');

  const handleIconClick = () => {
    if (isMobile) {
      if (isOpen) {
        handleMenuClose();
      } else {
        handleMenuOpen();
      }
    }
  };

  return (
    <>
      <motion.div
        className="nav-icon-wrapper"
        style={{ opacity }}
        onMouseEnter={!isMobile ? handleMenuOpen : undefined}
        onClick={isMobile ? handleIconClick : handleMenuClose}
      >
        <img
          className={`nav-icon ${isOpen ? 'open' : ''}`} // Corrected variable name
          src={PlusSign}
          width="45"
          height="45"
        />
      </motion.div>
      <AnimatePresence>
        {isOpen && (
          <>
            {isSafari ? (
              <motion.div
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={menuContentVariants}
                className={`bm-menu ${isOpen ? 'open' : 'close'}`}
                style={{ zIndex: 2500 }}
              >
                <Menu
                  isOpen={isOpen}
                  onStateChange={handleStateChange}
                  customBurgerIcon={false}
                  customCrossIcon={false}
                  width={'100%'}
                  noOverlay
                  pageWrapId="page-wrap"
                  outerContainerId="outer-container"
                >
                  <motion.div
                    className="menu-content"
                    variants={menuContentVariants}
                  >
                    <Projects
                      studioName={menuData.studioName}
                      sections={menuData.sections}
                      closeMenu={handleMenuClose}
                      menuItemVariants={menuItemVariants}
                      menuContentVariants={menuContentVariants}
                      hrElement={hrElement}
                      LinkComponent={CloseMenuLink}
                    />
                    {hrElement}
                    <Profile
                      about={menuData.about}
                      menuItemVariants={menuItemVariants}
                      closeMenu={handleMenuClose}
                      LinkComponent={CloseMenuLink}
                    />
                    {hrElement}
                    <Footer
                      selectedClients={menuData.selectedClients}
                      menuItemVariants={menuItemVariants}
                      closeMenu={handleMenuClose}
                      LinkComponent={CloseMenuLink}
                    />
                    {hrElement}
                    <Contact
                      contact={menuData.contact}
                      links={menuData.links}
                      closeMenu={handleMenuClose}
                      menuItemVariants={menuItemVariants}
                      LinkComponent={CloseMenuLink}
                    />
                    {specialHrElement}
                  </motion.div>
                </Menu>
              </motion.div>
            ) : (
              <motion.div
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={menuContentVariants}
                className={`bm-menu ${isOpen ? 'open' : 'close'}`}
              >
                <Menu
                  isOpen={isOpen}
                  onStateChange={handleStateChange}
                  customBurgerIcon={false}
                  customCrossIcon={false}
                  width={'100%'}
                  noOverlay
                >
                  <motion.div
                    className="menu-content"
                    variants={menuContentVariants}
                  >
                    <Projects
                      studioName={menuData.studioName}
                      sections={menuData.sections}
                      closeMenu={handleMenuClose}
                      menuItemVariants={menuItemVariants}
                      menuContentVariants={menuContentVariants}
                      hrElement={hrElement}
                      LinkComponent={CloseMenuLink}
                    />
                    {hrElement}
                    <Profile
                      about={menuData.about}
                      menuItemVariants={menuItemVariants}
                      closeMenu={handleMenuClose}
                      LinkComponent={CloseMenuLink}
                    />
                    {hrElement}
                    <Footer
                      selectedClients={menuData.selectedClients}
                      menuItemVariants={menuItemVariants}
                      closeMenu={handleMenuClose}
                      LinkComponent={CloseMenuLink}
                    />
                    {hrElement}
                    <Contact
                      contact={menuData.contact}
                      links={menuData.links}
                      closeMenu={handleMenuClose}
                      menuItemVariants={menuItemVariants}
                      LinkComponent={CloseMenuLink}
                    />
                    {specialHrElement}
                  </motion.div>
                </Menu>
              </motion.div>
            )}
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default NavIcon;
import React, { createContext, useState, useContext } from 'react';

const FooterLinksContext = createContext();

export const FooterLinksProvider = ({ children }) => {
  const [footerLinks, setFooterLinks] = useState({
    prevProjectLink: '/',
    nextProjectLink: '/',
    homeLink: '/'
  });

  return (
    <FooterLinksContext.Provider value={{ footerLinks, setFooterLinks }}>
      {children}
    </FooterLinksContext.Provider>
  );
};

export const useFooterLinks = () => {
  const context = useContext(FooterLinksContext);
  if (!context) {
    throw new Error('useFooterLinks must be used within a FooterLinksProvider');
  }
  return context;
};
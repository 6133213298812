import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { supabase } from '../../components/Supabase/Supabase'; // Import Supabase client

// Function to set a cookie
const setCookie = (name, value, days) => {
  const d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = name + '=' + value + ';' + expires + ';path=/';
  console.log(`Set cookie: ${name}=${value}; expires=${expires}; path=/`); // Debug log
};

// Function to get a cookie
const getCookie = (name) => {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

const useCookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // if the cookie consent has already been given
    const cookieConsent = getCookie('cookieConsent');
    console.log('cookieConsent:', cookieConsent); // Debug log
    if (!cookieConsent) {
      const timer = setTimeout(() => {
        console.log('Showing banner'); // Debug log
        setShowBanner(true);
      }, 1500);

      // Cleanup function
      return () => {
        clearTimeout(timer);
      };
    }
  }, [location.pathname]);

  const handleAccept = async () => {
    setCookie('cookieConsent', 'true', 365); // Set consent cookie for 1 year
    setShowBanner(false);

    // Log the consent data to Supabase
    const { error } = await supabase.from('cookie_consent').insert([
      {
        created_at: new Date().toISOString(), // Use the correct column name
        user_agent: navigator.userAgent,
      },
    ]);

    if (error) {
      console.error('Error logging cookie consent:', error);
    }
  };

  const handleClose = () => {
    setShowBanner(false);
  };

  return {
    showBanner,
    handleAccept,
    handleClose,
  };
};

export default useCookieConsent;
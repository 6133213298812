import { useState, useEffect } from 'react';
import useOpacityHook from '../NavIconScrollOpacity/useOpacityHook';

const useResponsiveMenu = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const [opacity] = useOpacityHook();

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;

      if (currentWidth >= 1024) {
        // Ensure the menu is not expanded when the width is 1024px or more
        setIsExpanded(false);
      } else {
        // Collapse the menu when the width is less than 1024px
        setIsExpanded(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call it initially to set the correct state

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (activeModal) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    };

    // Initial check
    handleResize();

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      document.body.classList.remove('no-scroll');
      window.removeEventListener('resize', handleResize);
    };
  }, [activeModal]);

  const handleMenuClick = (e) => {
    e.preventDefault();
    setIsExpanded(true);
  };

  const handleLinkHover = (link) => {
    if (link === 'Menu') {
      setIsExpanded(false);
      setActiveModal(null);
    } else if (link !== 'Typefaces' && isExpanded) {
      setActiveModal(link);
    }
  };

  const handleLinkClick = () => {
    if (isExpanded) {
      setIsExpanded(false);
      setActiveModal(null);
    }
  };

  return {
    isExpanded,
    activeModal,
    opacity,
    setActiveModal, // Ensure setActiveModal is returned
    handleMenuClick,
    handleLinkHover,
    handleLinkClick,
  };
};

export default useResponsiveMenu;

import React from 'react';

const CustomCloseIcon = () => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13.21 13.21"
    style={{ marginTop: '-4px' }}
  >
    <path
      d="M12.88,11.29l-4.69-4.69L12.88,1.92c.44-.44.44-1.15,0-1.59s-1.15-.44-1.59,0l-4.69,4.69L1.92.33C1.48-.11.77-.11.33.33s-.44,1.15,0,1.59l4.69,4.69L.33,11.29c-.44.44-.44,1.15,0,1.59s1.15.44,1.59,0l4.69-4.69,4.69,4.69c.44.44,1.15.44,1.59,0s.44-1.15,0-1.59Z"
      fill="white"
      strokeWidth="0"
    />
  </svg>
);

export default CustomCloseIcon;

import React from 'react';
import { motion } from 'framer-motion';

import '../css/SkeletonScreen.css'; // Import the CSS file for skeleton screen

import skeletonData from './SkeletonData'; // Import the skeletonData array

const SkeletonScreen = () => {
  const containerVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.2, delay: 0.05 },
    },
    exit: { opacity: 0, scale: 0, transition: { duration: 0.2 } },
  };

  const gridVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.2, delay: 0.05 },
    },
    exit: { opacity: 0, scale: 0, transition: { duration: 0.2 } },
  };

  return (
    <motion.div
      className="grid-container"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {skeletonData.map((item, index) => (
        <motion.div key={index} className="grid-item" variants={gridVariants}>
          <div
            className="skeleton-image"
            style={{ paddingTop: `${(1 / item.aspectRatio) * 100}%` }} // Apply aspect ratio dynamically
          ></div>
          <div className="caption-container">
            <div
              className="skeleton-caption"
              data-caption={item.caption}
              style={{ marginBottom: '6px' }}
            ></div>
          </div>
        </motion.div>
      ))}
    </motion.div>
  );
};

export default SkeletonScreen;

import { useState, useEffect } from 'react';

const useOpacityHook = () => {
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setOpacity(0.8);
      } else {
        setOpacity(1);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return [opacity, setOpacity];
};

export default useOpacityHook;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const withSimpleFadeOut = (WrappedComponent) => {
  return ({ to, children, closeMenu, ...props }) => {
    const navigate = useNavigate();

    const handleClick = (e) => {
      e.preventDefault();

      // Apply the fade out animation to elements
      const elementsToFadeQuick = document.querySelectorAll(
        '.slider-wrapper, .slider-box, .project-description'
      );
      const gridItems = document.querySelectorAll('.grid-item');
      const elementsToFadeNormal = document.querySelectorAll(
        '.arrowleft, .arrowright, .squarefoliohome, #cookieConsent, #return-to-top'
      );

      const fadeOutElements = (elements) => {
        elements.forEach((element) => {
          element.style.transition = 'opacity 0.1s ease';
          element.style.opacity = 0;
        });
      };

      // Apply the fade-out class to the outlet, nav-icon, nav-container, and nav-link elements
      const outlet = document.querySelector('.outlet');
      const navIcon = document.querySelector('.nav-icon');
      const navContainer = document.querySelector('.nav-container'); // Added nav-container
      const navLinks = document.querySelectorAll('.nav-link');

      if (outlet) {
        outlet.classList.add('fade-out');
      }

      if (navIcon) {
        navIcon.classList.add('fade-out');
      }

      if (navContainer) {
        navContainer.classList.add('fade-out'); // Apply fade-out to nav-container
      }

      navLinks.forEach((navLink) => {
        navLink.classList.add('fade-out');
      });

      // Fade out elements in standard order
      fadeOutElements(elementsToFadeQuick);
      fadeOutElements(gridItems);

      elementsToFadeNormal.forEach((element) => {
        element.style.transition = 'opacity 0.1s ease';
        element.style.opacity = 0;
      });

      // Wait for animations to complete before navigating
      setTimeout(() => {
        navigate(to);
      }, 50); // Adjust timeout to match the animation duration
    };

    useEffect(() => {
      // Remove the fade-out class after the page load
      const outlet = document.querySelector('.outlet');
      const navIcon = document.querySelector('.nav-icon');
      const navContainer = document.querySelector('.nav-container'); // Added nav-container
      const navLinks = document.querySelectorAll('.nav-link');

      if (outlet) {
        outlet.classList.remove('fade-out');
      }

      if (navIcon) {
        navIcon.classList.remove('fade-out');
      }

      if (navContainer) {
        navContainer.classList.remove('fade-out'); // Remove fade-out from nav-container
      }

      navLinks.forEach((navLink) => {
        navLink.classList.remove('fade-out');
      });
    }, [navigate]);

    return (
      <WrappedComponent to={to} onClick={handleClick} {...props}>
        {children}
      </WrappedComponent>
    );
  };
};

export default withSimpleFadeOut;
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

import CustomLink from '../../../../hooks/NavMenu/useNavigationLinkWithMenuControl';

const Projects = ({
  studioName,
  sections,
  closeMenu,
  menuItemVariants,
  menuContentVariants,
  hrElement,
}) => {
  useEffect(() => {
    const projectsElement = document.querySelector('.projects');
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      projectsElement.classList.add('safari');
    }
    if (/Edg/i.test(navigator.userAgent)) {
      projectsElement.classList.add('edge');
    }
  }, []);

  return (
    <>
      <motion.div className="title" variants={menuItemVariants}>
        {studioName}
      </motion.div>
      {hrElement}
      <motion.div
        className="projects suspense-toggle"
        variants={menuItemVariants}
      >
        <div className="projects-grid">
          {sections.map((section, index) => (
            <motion.div key={index} className={section.title.toLowerCase().replace(' ', '-')}>
              <motion.div className="title" variants={menuItemVariants}>
                {section.title}
              </motion.div>
              <motion.div variants={menuContentVariants}>
                <br />
                {section.items.map((item, itemIndex) => (
                  <React.Fragment key={itemIndex}>
                    {item.link ? (
                      <CustomLink closeMenu={closeMenu} to={item.link}>
                        {item.name}
                      </CustomLink>
                    ) : (
                      <motion.span variants={menuItemVariants}>
                        {item.name}
                      </motion.span>
                    )}
                    <br />
                  </React.Fragment>
                ))}
              </motion.div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </>
  );
};

export default Projects;
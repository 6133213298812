// useNavLink.js
import { useState, useEffect } from 'react';

const useNavLink = (isExpanded) => {
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [canActivateModal, setCanActivateModal] = useState(false);

  useEffect(() => {
    if (isExpanded) {
      const timeout = setTimeout(() => {
        setCanActivateModal(true);
      }, 300); // Delay to ensure menu expansion is complete
      return () => clearTimeout(timeout);
    } else {
      setCanActivateModal(false);
    }
  }, [isExpanded]);

  return {
    hoverTimeout,
    setHoverTimeout,
    canActivateModal,
  };
};

export default useNavLink;

import React, { useState } from 'react';
import { motion } from 'framer-motion';

import emailjs from 'emailjs-com';
import { supabase } from '../../../Supabase/Supabase';

import CustomMenuLink from '../../../../hooks/NavMenu/useNavigationLinkWithMenuControl'; // Adjust the import path as needed

const Contact = ({ contact, links, menuItemVariants, closeMenu }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendEmailNotification(email); // Send email notification
      await logEmail(email); // Log the email to Supabase
      closeMenu(); // Close the menu after successful email submission
    } catch (error) {
      console.error('Failed to send email.', error);
    }
    setEmail(''); // Clear the email input field
  };

  const sendEmailNotification = async (email) => {
    const templateParams = {
      to_email: contact.email,
      from_email: email,
      message: `Email: ${email}`,
    };

    await emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID, // Use environment variable
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID, // Use environment variable
      templateParams,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY // Use environment variable
    );
  };

  const logEmail = async (email) => {
    console.log('Logging email:', email);
    const { error } = await supabase
      .from('subscribers')
      .insert([{ emails_of_subscribers: email }]);
    if (error) {
      console.error('Error logging email:', error);
      throw error;
    }
    console.log('Email logged successfully');
  };

  return (
    <motion.div className="contact" variants={menuItemVariants}>
      <motion.span className="title" variants={menuItemVariants}>
        {contact.studioName}
      </motion.span>
      <br />
      <br />
      <motion.div variants={menuItemVariants}>
        {contact.address.street}
        <br />
        {contact.address.studio}
        <br />
        {contact.address.city}
        <br />
        {contact.address.postcode}
        <br />
      </motion.div>
      <br />
      <motion.div variants={menuItemVariants}>
        Email: <a href={`mailto:${contact.email}`}>{contact.email}</a>
        <br />
        Phone: <a href={`tel:${contact.phone}`}>{contact.phone}</a>
        <br />
      </motion.div>
      <br />
      <motion.div variants={menuItemVariants}>
        {links.map((link, index) => (
          <React.Fragment key={index}>
            <CustomMenuLink to={link.link} closeMenu={closeMenu}>
              {link.name}
            </CustomMenuLink>
            <br />
          </React.Fragment>
        ))}
      </motion.div>
      <br />
      <motion.div variants={menuItemVariants}>
        <div className="title mb-4">Newsletter</div>
        <form onSubmit={handleSubmit}>
          <label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="neon-placeholder contact-input"
              placeholder="Enter email"
              required
            />
          </label>
          <br />
          <button type="submit" className="subscribe-button">
            Subscribe
          </button>
        </form>
      <br></br>
      </motion.div>
    </motion.div>
  );
};

export default Contact;

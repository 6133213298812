import React from 'react';
import { Link } from 'react-router-dom';
import withSimpleFadeOut from './useSimpleFadeOutContent';

const SimpleFadeOutLink = ({ to, children, closeMenu, ...props }) => {
  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};

export default withSimpleFadeOut(SimpleFadeOutLink);
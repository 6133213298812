import React from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';

import Styles from './Styles';
import '../../css/DesktopNav.css';

import NavLink from '../Link/NavLink';
import Modal from '../Modal/Container/Modal';

import useNewnav from '../../../../hooks/NavMenu/useDesktopResponsiveMenu';
import useBodyScrollLock from '../../../../hooks/NavMenu/useBodyScrollLock';

const links = ['Menu', 'Projects', 'About', 'Contact', 'Typefaces'];

export default function Newnav() {
  const {
    isExpanded,
    activeModal,
    opacity,
    setActiveModal,
    handleMenuClick,
    handleLinkHover,
  } = useNewnav();

  const location = useLocation();

  // Check if the current path is the typeface page
  const isTypefacePage = location.pathname === '/Typefaces';

  // Use the custom hook to lock body scroll when the modal is active
  useBodyScrollLock(!!activeModal);

  return (
    <div className="nav-container" style={Styles.navContainer}>
      {!isTypefacePage && (
        <div className="flex justify-center mt-4">
          <nav className="relative">
            <motion.div
              initial={{ width: 'auto' }}
              animate={{ width: isExpanded ? 'auto' : 'auto' }}
              transition={{ duration: 0.1, ease: 'easeInOut' }}
              className="flex items-center"
            >
              {links.map((link, index) => (
                <NavLink
                  key={link}
                  link={link}
                  isExpanded={isExpanded}
                  index={index}
                  handleLinkHover={handleLinkHover}
                  handleMenuClick={handleMenuClick}
                  linkTextStyle={Styles.linkText(opacity)}
                  customCursorStyle={Styles.customCursor}
                />
              ))}
            </motion.div>
          </nav>
        </div>
      )}
      <Modal
        activeModal={activeModal}
        setActiveModal={setActiveModal}
        modalOverlayStyle={Styles.modalOverlay}
        modalContentStyleWebkit={Styles.modalContent}
        modalContentClass={activeModal ? 'no-scroll' : ''}
      />
    </div>
  );
}
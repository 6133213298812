import React from 'react';

import '../css/NavStore.css'; // Import the CSS file

import CustomLink from '../../../hooks/Global/FadeOutElements/useContentFadeOutOnPageTransition'; // Ensure this path is correct
import useOpacityHook from '../../../hooks/NavIconScrollOpacity/useOpacityHook'; // Ensure this path is correct

const NavStore = () => {
  const [opacity] = useOpacityHook();

  // Define the handleClick function
  const handleClick = () => {
  
  };

  return (
    <div className={`outlet`} style={{ opacity }}>
      <CustomLink
        className="custom-tooltip"
        style={{ backgroundImage: 'none' }}
        to="/Typefaces"
        onClick={handleClick}
      >
        <svg viewBox="0 0 20.08 20.76" width={50} height={50}>
          <defs>
            <style>
              {`
                .cls-1 {
                  fill: none;
                }
                .cls-1, .cls-2 {
                  stroke-width: 0px;
                }
              `}
            </style>
          </defs>
          <rect
            className="cls-1"
            x="1.9"
            y="8.81"
            width="15.58"
            height="9.53"
          />
          <path
            className="cls-2"
            d="M18.96,6.73h-3.43v-1.25c0-3.03-2.46-5.49-5.49-5.49s-5.49,2.46-5.49,5.49h2.25c0-1.79,1.45-3.24,3.24-3.24s3.24,1.45,3.24,3.24h2.21s-2.21,0-2.21,0v1.25h-6.48v-1.25h-2.25v1.25H1.12c-.62,0-1.12.5-1.12,1.12v11.78c0,.62.5,1.12,1.12,1.12h17.83c.62,0,1.12-.5,1.12-1.12V7.86c0-.62-.5-1.12-1.12-1.12ZM17.83,18.51H2.25v-9.53h15.58v9.53Z"
          />
        </svg>
        <span className="tooltip-text tooltip-store">Typefaces</span>
      </CustomLink>
    </div>
  );
};

export default React.memo(NavStore);
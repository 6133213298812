import React, { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const NavigationLinkWithMenuControl = ({ to, children, closeMenu, isMenuLink = true }) => {
  const navigate = useNavigate();

  const handleNavigation = useCallback((e) => {
    if (isMenuLink) {
      e.preventDefault(); // Prevent default only for menu links

      const menuContentElements = document.querySelectorAll('.menu-content > *:not(.nav-icon):not(.outlet):not(.nav-link):not(.nav-container)');
      
      menuContentElements.forEach((element, index) => {
        element.style.setProperty('--stagger-index', index);
        element.classList.add('exiting');
      });
      
      document.body.classList.add('hide-pseudo-elements');
      
      // Use requestAnimationFrame to ensure the exiting class is applied before navigation
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          if (typeof closeMenu === 'function') {
            closeMenu();
          }
          document.body.classList.add('menu-open');
          document.body.classList.remove('hide-pseudo-elements');
          
          // Set a timeout to allow for the staggering animation
          setTimeout(() => {
            menuContentElements.forEach((element) => {
              element.classList.remove('exiting');
            });
            navigate(to);
          }, 200); // Adjust this timing based on your staggering animation duration
        });
      });
    } else {
      // For non-menu links, just navigate normally
      if (typeof closeMenu === 'function') {
        closeMenu();
      }
      navigate(to);
    }
  }, [to, closeMenu, navigate, isMenuLink]);

  return (
    <Link to={to} onClick={handleNavigation}>
      {children}
    </Link>
  );
};

export default NavigationLinkWithMenuControl;
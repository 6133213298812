import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import CustomLink from '../../../../hooks/Global/FadeOutElements/useContentFadeOutOnPageTransitionTypefaceLinks'; // Import the CustomLink component
import useNavLink from '../../../../hooks/NavMenu/useDesktopNavLink'; // Import the custom hook

const NavLink = ({
  link,
  isExpanded,
  index,
  handleLinkHover,
  handleMenuClick,
  linkTextStyle,
  closeMenu,
}) => {
  const isHoverOnly = ['Projects', 'About', 'Contact'].includes(link);
  const { hoverTimeout, setHoverTimeout, canActivateModal } =
    useNavLink(isExpanded);

  const customCursorStyle = {
    cursor:
      "url(\"data:image/svg+xml,%3Csvg%20id='Layer_1'%20xmlns='http://www.w3.org/2000/svg'%20width='34'%20height='34'%20viewBox='0%200%2013.21%2013.21'%3E%3Cpath%20d='M12.88,11.29l-4.69-4.69L12.88,1.92c.44-.44.44-1.15,0-1.59s-1.15-.44-1.59,0l-4.69,4.69L1.92.33C1.48-.11.77-.11.33.33s-.44,1.15,0,1.59l4.69,4.69L.33,11.29c-.44.44-.44,1.15,0,1.59s1.15.44,1.59,0l4.69-4.69,4.69,4.69c.44.44,1.15.44,1.59,0s.44-1.15,0-1.59Z'%20fill='%23b026ff'%20stroke-width='0'/%3E%3C/svg%3E\") 16 16, pointer",
  };

  const handleMouseEnter = (link) => {
    if (canActivateModal) {
      handleLinkHover(link);
    }
  };

  const handleMouseLeave = () => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
  };

  return (
    <motion.div
      key={link}
      initial={{
        opacity: index === 0 ? 1 : 0,
        width: index === 0 ? 'auto' : 0,
      }}
      animate={{
        opacity: isExpanded ? 1 : index === 0 ? 1 : 0,
        width: isExpanded ? 'auto' : index === 0 ? 'auto' : 0,
      }}
      transition={{ duration: 0.05, ease: 'easeInOut' }}
      className="flex items-center whitespace-nowrap overflow-hidden"
      style={{ margin: '0 0px', ...customCursorStyle }} // Apply custom cursor style here
      onClick={closeMenu} // Add closeMenu here
    >
      {index > 0 && isExpanded && (
        <span>
          <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            viewBox="0 0 25 25"
          >
            <polyline
              points="13.04 7.02 18.3 12.29 13.04 17.55"
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.25"
            />
            <polygon
              points="17.13 12.29 18.3 12.29 5.8 12.29 17.13 12.29"
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.25"
            />
            <rect width="25" height="25" fill="none" strokeWidth="0" />
          </svg>
        </span>
      )}
      {isHoverOnly ? (
        <div
          className={`px-1 py-1 rounded transition-colors nav-link`}
          onMouseEnter={() => handleMouseEnter(link)}
          onMouseLeave={handleMouseLeave}
          style={{ margin: '0 -4px', ...customCursorStyle }} // Apply custom cursor style here
        >
          <span style={linkTextStyle}>{link}</span>
        </div>
      ) : link === 'Typefaces' ? (
        <CustomLink
          to="/Typefaces"
          className={`px-1 py-1 rounded transition-colors nav-link`}
          onMouseEnter={() => handleMouseEnter(link)}
          onMouseLeave={handleMouseLeave}
          style={{ margin: '0 -4px', ...customCursorStyle }} // Apply custom cursor style here
    
        >
          <span style={linkTextStyle}>{link}</span>
        </CustomLink>
      ) : (
        <Link
          to={`/${link.toLowerCase()}`}
          className={`px-1 py-1 rounded transition-colors nav-link`}
          onMouseEnter={() => handleMouseEnter(link)}
          onMouseLeave={handleMouseLeave}
          onClick={link === 'Menu' ? handleMenuClick : undefined}
          style={{ margin: '0 -4px', ...customCursorStyle }} // Apply custom cursor style here
        >
          <span style={linkTextStyle}>{link}</span>
        </Link>
      )}
    </motion.div>
  );
};

export default NavLink;